








































import { Component, Vue, Prop } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { BLANK_ID, STANDARD_ID } from "@/constants/PriceListTemplates";

@Component({
  components: { Validation, SaveButton, AbortButton },
})
export default class CreateCard extends Vue {
  public name = "CreateCard";

  @Prop({ type: Array, default: () => [] })
  public priceOptions!: Array<any>;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  @Prop({ type: String, default: () => "", required: false })
  public priceListNameForEdit?: string;

  @Prop({ type: Boolean, default: false, required: false })
  public disablePriceOptions?: boolean;

  public priceListName = "";
  public template = "";

  public created() {
    if (this.priceListNameForEdit) {
      this.priceListName = this.priceListNameForEdit;
    }
  }
  protected onAbort(): void {
    this.$emit("on-abort");
  }

  protected onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.$emit("on-submit", {
      priceListName: this.priceListName,
      template: this.template,
    });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      priceListName: { required },
      template: { required },
    };
  }

  public get priceSelectOptions(): Array<any> {
    return this.priceOptions.reduce(
      (templates: any, priceOption: any) => {
        templates.push(priceOption);
        return templates;
      },
      [
        {
          name: this.$t("price_list.blank"),
          id: BLANK_ID,
        },
        {
          name: this.$t("price_list.standard_price_list"),
          id: STANDARD_ID,
        },
      ]
    );
  }
}
