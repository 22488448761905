





























































import { Component, Vue, Prop } from "vue-property-decorator";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import AbortButton from "@/components/Button/AbortButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import FilterButton from "@/components/Button/FilterButton.vue";

@Component({
  components: {
    FilterButton,
    FscModal,
    AbortButton,
  },
})
export default class PriceListFilter extends Vue {
  public name = "PriceListFilter";

  @Prop({ default: () => [] })
  public allLicenseClasses: any;

  @Prop({ default: () => [] })
  public grantingTypes!: Array<IBasicNamedDTO>;

  @Prop({ default: () => [] })
  public vats: any;

  @Prop()
  public priceList!: any;

  @Prop({ type: String })
  public modalId!: string;

  public selectedLicenseClasses = [];

  public selectedGrantingType: any = null;

  public selectedVat: any = null;

  public selectedBookAtStudentRegistration = null;

  public mounted(): void {
    if (sessionStorage.getItem("priceListFilter") !== null) {
      this.localStorageGetPriceListFilter(sessionStorage.getItem("priceListFilter"));
    }
  }

  public onAbortFilter(cancel: () => void): void {
    this.selectedLicenseClasses = [];
    this.selectedGrantingType = null;
    this.selectedVat = null;
    this.selectedBookAtStudentRegistration = null;
    const filter = {
      offset: 0,
      searchTerm: null,
      priceListId: this.priceList.id,
      licenseClassNames: null,
      grantingTypeId: null,
      vatId: null,
      bookAtStudentRegistration: null,
    };

    this.$emit("on-abort-filter", {
      filter: filter,
    });
    cancel();
  }

  public onFilter(ok: () => void): void {
    const filter = {
      offset: 0,
      searchTerm: null,
      priceListId: this.priceList.id,
      licenseClassNames: this.licenseClasses,
      grantingTypeId: this.selectedGrantingType ? this.selectedGrantingType.id : null,
      vatId: this.selectedVat ? this.selectedVat.id : null,
      bookAtStudentRegistration: this.selectedBookAtStudentRegistration,
    };
    this.localStorageSetPriceListFilter();
    this.$emit("on-filter", {
      filter: filter,
    });
    ok();
  }

  public get licenseClassOptions(): Array<any> {
    return this.allLicenseClasses;
  }

  public get grantingTypesOptions(): Array<IBasicNamedDTO> {
    return this.grantingTypes;
  }

  public get vatOptions(): Array<any> {
    return this.vats;
  }

  private localStorageSetPriceListFilter(): void {
    const filter = {
      licenseClassNames: this.selectedLicenseClasses,
      grantingTypeId: this.selectedGrantingType,
      vatId: this.selectedVat,
      bookAtStudentRegistration: this.selectedBookAtStudentRegistration,
    };
  }

  private localStorageGetPriceListFilter(filter: any): void {
    const savedPriceListFilter = JSON.parse(filter);
    if (savedPriceListFilter) {
      this.selectedLicenseClasses = savedPriceListFilter.licenseClassNames;
      this.selectedGrantingType = savedPriceListFilter.grantingTypeId;
      this.selectedVat = savedPriceListFilter.vatId;
      this.selectedBookAtStudentRegistration = savedPriceListFilter.bookAtStudentRegistration;
    }
  }

  public get licenseClasses(): any {
    if (this.selectedLicenseClasses.length === 0) {
      return null;
    } else {
      return this.selectedLicenseClasses;
    }
  }

  protected get bookAtStudentRegistrationOptions(): Array<any> {
    return [
      {
        label: this.$t("general.no"),
        value: false,
      },
      {
        label: this.$t("general.yes"),
        value: true,
      },
    ];
  }
}
